import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box } from "theme-ui";
import PictureSet from "../components/PictureSet";
import imgSet02 from "../imgsets/imgset02";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Kölner Weg" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet02} mdxType="PictureSet" />
    </Wide>
    <h1>{`Kölner Weg`}</h1>
    <p>{`Im Kölner Weg haben wir die 15 Stationen zusammengefasst, die auf dem Weg von der Ideenfindung zum Wachstum wichtig sind. Damit habt ihr den Überblick. Nicht immer müssen alle Stationen durchlaufen werden und auch die Reihenfolge kann natürlich variieren. Aber alle Stationen sind fast immer wichtig und erfolgreiche Gründerinnen und Gründer haben sich mit den Themen der meisten Stationen tief auseinandergesetzt.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQJB//EABcBAAMBAAAAAAAAAAAAAAAAAAABBgj/2gAMAwEAAhADEAAAAdjVl2vpCVrwR4Ef/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQIDBQgABAYVIv/aAAgBAQABBQKZsq9sOcfsFH9YmzcdrqcACDj59//EACURAAADBAsAAAAAAAAAAAAAAAECEQADBBIUFSExQVNhcYGS0v/aAAgBAwEBPwGnRU0wvF0EpRLamCbcKFzVjF5gdCeW/8QAIBEAAgEEAgMBAAAAAAAAAAAAAQIDBAYREgAiIyRhY//aAAgBAgEBPwEWZbhgEC0JjGy+WOeZJzpsuDIHzqwfuowGYK57qG4LBthgGNDJkgZ9yr+ftz//xAAoEAACAgEDAgQHAAAAAAAAAAACAwEEEgAFEQYhJDFBUSIjcoOh4fD/2gAIAQEABj8CaO17LZFBKtvpssPr1bVNxUrFWvD1qK2i+hbnRaJWaCyWI5lHlULq3YZvbzVSKrFiibVTfXXxmnYGCbFdLsu9lczjJZEqBDFZNGOl7OBtJ45bswz8Rw5kH4fCOHG3EV/BC8PXnS+O3ypjt7cfvX2Bj8x/c+eo+gfSPbX/xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhQVFhkbHR/9oACAEBAAE/IUg2HFYXDH6gGIGHxCj0NFRPYtGymCGBaJKSAZFU0upKgox3VO97c7+BrXp7+FmLw+kIc8Gf/9oADAMBAAIAAwAAABCs3//EABkRAQEBAAMAAAAAAAAAAAAAAAERIVHB8P/aAAgBAwEBPxBtAmgQQ7QqOFqQaRTUIizjp19c/8QAGREBAQEBAQEAAAAAAAAAAAAAAREhMQBx/9oACAECAQE/EBBjpIElCLpqSW1VF3ZS1ayu6/X3/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8Qdv1x4vrOv5J0qnfPDIWWKFA3YGoFJAUhFnM9hjJp4BsptbbWOwtA4RjVoHz2GuKB8I8mAt6gfPZ//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "\"\"",
          "title": "\"\"",
          "src": "/static/b44a0e4a74cdbe688c7326a098adfe6a/0a251/hgnc_Koelner-Weg_v2.3.0.de.jpg",
          "srcSet": ["/static/b44a0e4a74cdbe688c7326a098adfe6a/a3e66/hgnc_Koelner-Weg_v2.3.0.de.jpg 480w", "/static/b44a0e4a74cdbe688c7326a098adfe6a/fb816/hgnc_Koelner-Weg_v2.3.0.de.jpg 960w", "/static/b44a0e4a74cdbe688c7326a098adfe6a/0a251/hgnc_Koelner-Weg_v2.3.0.de.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Warum heißt der Kölner Weg so? Das hat historische Gründe. Als sich das hgnc vor vielen Jahren in Köln und Umgebung zusammengeschlossen hat, wurde dieser Begriff für den Weg des Gründers geprägt.`}</p>
    <p>{`Entsprechend haben wir unser Angebot an den Stationen des Kölner Wegs ausgerichtet, um Gründungsinteressierten den Weg in die Selbstständigkeit zu erleichtern. Zu jeder Station bieten dir das hgnc oder seine Mitglieder ein spezielles Angebot an:`}</p>
    <h2>{`Angebote zu den Stationen`}</h2>
    <details>
  <summary>Produkt-/DL-Idee, Lösung für Kundenproblem</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: Design Thinking und Ideenfindung, Frank Barz, Telekom`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Workshop: “Wie gut ist deine Geschäftsidee?“; Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis`}</p>
          <p parentName="li">{`Netzwerk/Vermittlung: Kontaktschaltung zu Unternehmen, jeweils ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: Allgemeine betriebswirtschaftliche persönliche Beratung: Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Marktanalyse</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Beratungstage: Know-how für Existenzgründer aus erster Hand, jeden Donnerstag von 14 bis 18 Uhr, Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Umsatz-/Gewinnerzielungsstrategie</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: Lasst uns heute ein Unternehmen gründen, Thilo Heyer`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Beratungstage: Know-how für Existenzgründer aus erster Hand, jeden Donnerstag von 14 bis 18 Uhr, Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Gründerteam & Skill Sets</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`- `}</strong>{`Kreissparkasse Köln`}<strong parentName="p">{`:`}</strong>{`: Veranstaltung:InfoForum 3: Netzwerken – Die Kompetenz der Zukunft. Veranstaltung: InfoForum 4: Führung im KI-Zeitalter – Was Maschinen nicht kopieren können! Veranstaltung: InfoForum 6: Leistungssport Berufsalltag – Treibstoff oder Treibsand?`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis`}</p>
          <p parentName="li">{`Motivationsanalysen nach Reiss und LUXX, jeweils ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Rheinische Fachhochschule Köln GmbH`}</strong>{`: Beratung von Gründerteams, Alina Ley, `}<a parentName="p" {...{
              "href": "mailto:alina.ley@rfh-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`alina.ley@rfh-koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Rechtsform, Anteile & Verträge</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: Freiberufler`}{`*`}{`innen – Selbstständige eigener Art, Dr. Anouschka Strang`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
          <p parentName="li">{`Informationsveranstaltung „Gründungskompass“, monatlich`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a>{`;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman, 02204-842470; `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Marken- & Schutzrechte</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Geschäftsmodell</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`GATEWAY Exzellenz Start-up Center`}</strong>{`: Beratung zu Geschäftsmodellen: Vesna Domuz, 0221/470 -1127, `}<a parentName="p" {...{
              "href": "mailto:v.domuz@verw.uni-Koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`v.domuz@verw.uni-Koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
          <p parentName="li">{`Online-Tool: Gründungswerkstatt NRW Infobroschüre: „Der Einstieg für Ihr erfolgreiches Unternehmen“`}</p>
          <p parentName="li">{`Kurzfilm: „Die IHK erklärt: Business Model Canvas“`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`:: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
          <p parentName="li">{`Workshop “Wie gut ist deine Geschäftsidee?“; Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Rheinische Fachhochschule Köln GmbH`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung für Digitalisierungsthemen, Prof. Kai Buehler, `}<a parentName="p" {...{
              "href": "mailto:kai.buehler@rfh-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`kai.buehler@rfh-koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Veranstaltung: Der Businessplan: Erfolgreich selbständig werden, Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NRW.BANK`}</strong>{`: Beratung, individuell: Timon Gottschalk (NRW.BANK), Mail: `}<a parentName="p" {...{
              "href": "mailto:timon.gottschalk@nrwbank.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`timon.gottschalk@nrwbank.de`}</a>{`, tel. 0211-91741 7623`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Finanz- & Geschäftsplan</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: NUK & hgnc Workshop: “Die Zukunft planbar machen – Finanzplanung leicht gemacht”, Jörg Püschel`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Kalkulationstool: „Gründungstool“ + „Gründungswerkstatt NRW“`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
      </ul>
      <p>{`Netzwerkveranstaltung: Prämierungsveranstaltung des NUK-Businessplan-Wettbewerbs; Judith Mertens; `}<a parentName="p" {...{
          "href": "https://neuesunternehmertum.de/termine",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://neuesunternehmertum.de/termine`}</a></p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`:: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Veranstaltung: Der Businessplan: Erfolgreich selbständig werden; Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NRW.BANK`}</strong>{`: Beratung, individuell: Timon Gottschalk (NRW.BANK), Mail: `}<a parentName="p" {...{
              "href": "mailto:timon.gottschalk@nrwbank.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`timon.gottschalk@nrwbank.de`}</a>{`, tel. 0211-91741-7623`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Marketing- & Vertriebsplan</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Erfolgsfaktor Wunschkunde / Selbstmarketing, Kundengewinnung, Katrin Busch-Holfelder`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`:: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis`}</p>
          <p parentName="li">{`Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Rheinische Fachhochschule Köln GmbH`}</strong>{`: Gründungsberatung der RFH; Beratung zu Marketing-& Vertriebswegen, Alina Ley, `}<a parentName="p" {...{
              "href": "mailto:alina.ley@rfh-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`alina.ley@rfh-koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Finanzierung & Förderung</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DSHS`}</strong>{`: Beratung zu öffentlichen Förderprogrammen, Gründungsberatung DSHS, Dr. Marita Mehlstäubl, m.mehlstaeubl@­dshs-koeln.de`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`GATEWAY Exzellenz Start-up Center`}</strong>{`: Beratung zu Förderprogrammen: Vesna Domuz, 0221/470 -1127, `}<a parentName="p" {...{
              "href": "mailto:v.domuz@verw.uni-Koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`v.domuz@verw.uni-Koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: Fördermittel für Hochschulgründungen, Prof. Dr. Kai Thierhoff`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Informationsveranstaltung „Gründungskompass“`}</p>
        </li>
      </ul>
      <p>{`Infobroschüre: „Ihr Wegweiser zu Finanzierung und Fördermitteln“`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`:: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
          <p parentName="li">{`Beratung: „Finanzierungsanfrage und -umsetzung“, Bereich Gründung und Spezialfinanzierung; 0221-2272478`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RZT Köln GmbH`}</strong>{`: allgemeine betriebswirtschaftliche persönliche Beratung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Beratungstage: Know-how für Existenzgründer aus erster Hand, jeden Donnerstag von 14 bis 18 Uhr; Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Beratung: Startercenter NRW, KölnBusiness Wirtschaftsförderungs-GmbH Beratung, individuell: Timon Gottschalk (NRW.BANK), Mail: `}<a parentName="p" {...{
              "href": "mailto:timon.gottschalk@nrwbank.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`timon.gottschalk@nrwbank.de`}</a>{`, tel. 0211-91741 7623`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`TH Köln`}</strong>{`: Stipendienbeantragung (Exist, GO-bio) und Gründungsberatung für Hochschulausgründungen; Gründungsservice TH Köln, laufend, `}<a parentName="p" {...{
              "href": "mailto:gruendungsservice@th-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`gruendungsservice@th-koeln.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Räume & Infrastruktur</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Mietraumangebot für Gründer: anforderungsgerechte Raumangebote ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RTZ`}</strong>{`: es stehen Büros, Labore (S1/S2), Arbeitsplätze für Startups und KMU’s, Besprechungsräume in unterschiedlichen Größen zur Verfügung, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Stadt Köln, Wirtschaftsförderung`}</strong>{`: Übersicht der Coworkingspaces in Köln, bei größerem Raumbedarf berät der Unternehmensservice der KölnBusiness, Antje Lienert, `}<a parentName="p" {...{
              "href": "mailto:antje.lienert@koeln.business.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`antje.lienert@koeln.business.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`TH Köln`}</strong>{`: Co-Working-Space / Maker-Space / Inkubator für Hochschulausgründungen; Inkubator / Gründungsservice TH Köln, laufend, `}<a parentName="p" {...{
              "href": "mailto:gruendungsservice@th-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`gruendungsservice@th-koeln.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Realisierung & Markteintritt</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: In 7 Schritten zur Erfolgreichen Marketingkampagne, Silvia Knecht Veranstaltung: Verkaufen ist nicht alles, aber ohne Verkaufen ist alles nichts, Gerd Hauer`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`: Veranstaltung: BWA – Kennen Sie die Bedienungsanleitung?; Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Kreissparkasse Köln`}</strong>{`:: Veranstaltung: InfoForum 1, Gründen mit System – Starthilfe für Existenzgründung!`}</p>
          <p parentName="li">{`Veranstaltung: InfoForum 5, Nachfolge – Stabwechsel im Chefsessel.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Erste Traktion</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching: Gründerwerkstatt; zeitbefristetes Raumangebot ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Pitches & weitere Finanzierung</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`DSHS`}</strong>{`: Pitch von Businessplänen: „Auf die Plätze, fertig – Pitch! – Studierende präsentieren Ihre Semesterprojekte“ – Anja Chevalier, `}<a parentName="p" {...{
              "href": "mailto:a.chevalier@dshs-koeln.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`a.chevalier@dshs-koeln.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`hgnc`}</strong>{`: Veranstaltung: Kommunikation für Gründer, Martin Sutoris`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
          <p parentName="li">{`Workshop „Der perfekte Pitch“, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
          <p parentName="li">{`Netzwerkveranstaltung: Prämierungsveranstaltung des NUK-Businessplan-Wettbewerbs, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Sparkasse KölnBonn`}</strong>{`:`}</p>
          <p parentName="li">{`Beratungstage: Know-how für Existenzgründer aus erster Hand, jeden Donnerstag von 14 bis 18 Uhr; Gründercenter, Charles-de-Gaulle-Platz 1 d, 50679 Köln; 0221-22694449`}</p>
          <p parentName="li">{`Beratung: „Finanzierungsanfrage und -umsetzung“, KSK-Wagniskaptital GmbH; 0221-2274887`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman, 02204-842470; `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NRW.BANK`}</strong>{`: Beratung, individuell: Timon Gottschalk (NRW.BANK), Mail: `}<a parentName="p" {...{
              "href": "mailto:timon.gottschalk@nrwbank.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`timon.gottschalk@nrwbank.de`}</a>{`, tel. 0211-91741-7623`}</p>
        </li>
      </ul>
    </details>
    <details>
  <summary>Wachstum & Internationalisierung</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`IHK`}</strong>{`: Workshopreihe „Gründerthemen“, 2. Hälfte 2020`}</p>
          <p parentName="li">{`Beratung: Petra Göbbels, `}<a parentName="p" {...{
              "href": "mailto:petra.goebbels@koeln.ihk.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`petra.goebbels@koeln.ihk.de`}</a>{`; Mathias Härchen, `}<a parentName="p" {...{
              "href": "mailto:mathias.haerchen@koeln.ihk.deInfomaterial",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`mathias.haerchen@koeln.ihk.deInfomaterial`}</a>{` zu: Startup- u. Unternehmensreisen; konkrete Unterstützung im Ausland`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`NUK`}</strong>{`: Beratung mit individuellem Coaching, Meet a coach, Judith Mertens; `}<a parentName="p" {...{
              "href": "https://neuesunternehmertum.de/termine",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://neuesunternehmertum.de/termine`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RBTZ`}</strong>{`: Coaching im Rahmen der Geschäftsmodellentwicklung; für technologieorientierte Gründungen im Rheinisch-Bergischen Kreis`}</p>
          <p parentName="li">{`Vermittlung: Kontaktschaltung zu Partnern ganzjährig, Birgit Engelman; 02204-842470, `}<a parentName="p" {...{
              "href": "https://www.tz-bg.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.tz-bg.de`}</a></p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`RTZ`}</strong>{`: Beratung in Wachstumsphasen sowie flexible Erweiterung zusätzl. Büros und Labore möglich, Ellen Kappel, 0221-8391114, `}<a parentName="p" {...{
              "href": "mailto:ellen.kappel@rtz.de",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`ellen.kappel@rtz.de`}</a></p>
        </li>
      </ul>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      